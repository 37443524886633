@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
@import "@ucdavis/gunrockin/sass/gunrockin.scss";
$primary-color: #14447a;
$secondary-color: $california;
$theme-colors: (
  primary: $primary-color,
  secondary: $secondary-color,
);
@import "bootstrap/scss/bootstrap";
@import "@ucdavis/gunrockin/sass/_typography.scss";

$bg-primary: #fff;
$bg-secondary: #f8f8f8;
$danger: $merlot;
$error-color: $danger;

$borders-secondary: #e5eaef;

$primary-light: #ebf1ee;

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
  }
}

//header

.bg-dark {
  background-color: $primary-color !important;
}
//search bar

.sidebar {
  box-shadow: $box-shadow;
  .form-group {
    border-bottom: 1px solid $borders;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
  label {
    strong {
      margin-bottom: 0px;
    }
  }
}

// vendor

.page-item.active .page-link {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}
.page-link {
  color: $primary-color;
}

.fa-star {
  color: #fff;
}

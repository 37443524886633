h1,
h2,
h3,
h4 {
  color: $primary-color;
}
h1 {
  font-weight: 600;
  font-size: 2rem;
  a {
    text-decoration: underline;
  }
}
h2 {
  font-size: 1.4rem;
}
h4 {
  font-size: 1.2rem;
  color: $secondary-font;
}
h5 {
  text-transform: uppercase;
  color: $primary-color;
  font-size: 1.2rem;
  font-family: $sans-bold;
}
a {
  color: $primary-color;
  &:hover {
    color: darken($primary-color, 0.4);
  }
}
p {
  font-size: 1rem;
  &.subtitle {
    margin-bottom: 0;
  }
  &.lead {
    max-width: 598px;
  }
}
.discreet {
  font-style: italic;
}

.btn {
  font-family: $sans-bold;
  font-size: 1.2rem;
  padding: 8px 20px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
.btn-primary {
  &:hover {
    background-color: lighten($primary-color, 7);
    border-color: lighten($primary-color, 7);
  }
}
.btn-light {
  background-color: lighten($primary-color, 9);
  border-color: lighten($primary-color, 9);
  &:hover {
    background-color: lighten($primary-color, 5) !important;
  }
}
.btn-outline-disabled {
  background-color: #fff;
  border-color: $secondary-font;
  color: $secondary-font;
  &:hover {
    background-color: #fff;
    border-color: lighten($secondary-font, 7);
    color: lighten($secondary-font, 7);
  }
}
.btn-outline {
  background-color: #fff;
  border-color: $primary-color;
  color: $primary-color;
  &:hover {
    background-color: #fff;
    border-color: lighten($primary-color, 7);
    color: lighten($primary-color, 7);
  }
}
.btn-outline-danger {
  background-color: #fff;
  border-color: $merlot;
  color: $merlot;
  &:hover {
    background-color: #fff;
    border-color: lighten($merlot, 9);
    color: lighten($merlot, 9);
  }
}
.btn-secondary {
  background-color: $california;
  border-color: $california;
  color: #fff;
  &:hover {
    background-color: lighten($california, 7);
    border-color: lighten($california, 7);
    color: #fff;
  }
}
.btn-sunflower {
  background-color: #d5b804;
  border-color: #d5b804;
  color: #fff;
  &:hover {
    background-color: lighten(#d5b804, 7);
    border-color: lighten(#d5b804, 7);
    color: #fff;
  }
}
.btn-danger {
  background-color: $merlot;
  border-color: $merlot;
  color: #fff;
  &:hover {
    background-color: lighten($merlot, 7);
    border-color: lighten($merlot, 7);
    color: #fff;
  }
}
.btn-sm {
  font-size: 1rem;
}
.box-shadow {
  box-shadow: $box-shadow;
}
.btn:focus,
.btn.focus {
  box-shadow: none;
}
.btn-link {
  color: $primary-color;
  background: rgba(0, 0, 0, 0);
  border: 0px;
  box-shadow: none;
  padding: 4px 0 4px 0;
  text-decoration: none !important;
  &:hover {
    color: darken($primary-color, 3);
    text-decoration: underline;
  }
}
.btn-fa {
  padding: 0;
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 1rem 1.5rem;
}
.btn-light {
  color: $primary-color;
  &:hover {
    background-color: $bg-primary;
    color: $primary-color;
  }
}

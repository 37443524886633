.badge-plantType {
  color: #333;
  background-color: #eeeeee;
  border: 1px solid #dddddd;
}
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  overflow-x: hidden;
  overflow-y: auto;
}

.react-toast-notifications__container {
}

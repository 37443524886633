html,
body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
  background-color: $bg-primary;
}
.page-wrap {
  flex: 1 0 auto;
}
.container {
  padding: 0;
  width: 98%;
  max-width: 1440px;
  @include breakpoint(sm) {
    width: 96%;
  }
  @include breakpoint(md) {
    width: 90%;
  }
  @include breakpoint(lg) {
    width: 86%;
  }
}
header {
  background-color: $primary-color;
  color: #fff;
  padding: 1% 0;
}

footer {
  margin-top: 50px;
}
